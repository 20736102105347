.header {
    width: 100%;

    header mark {
        background-color: yellow;
        color: black;
        font-weight: bold;
    }

    &__welcome {
        width: 100%;
        height: 23px;
        background-color: gray;
        text-align: center;

        &-title {
            color: rgb(231, 231, 231);
            font-size: 100%;
            margin: 0;
        }
    }

    &__home {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 0 20px;
        position: relative;

        &-title {
            font-size: 155%;
            font-family: sans-serif;
            text-decoration: none;
            color: black;
        }

        &-search {
            display: flex;
            align-items: center;
            position: relative;
            width: 300px;
        }
    }

    &__search-pole {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        transition: box-shadow 0.3s ease;

        &--active {
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
    }

    &__search-ul {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 150px;
        overflow-y: auto;
        background: white;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }

    &__search-li {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        font-size: 14px;
    }

    &__search-li a {
        text-decoration: none;
        color: black;
    }

    &__search-li a:hover {
        background: #f0f0f0;
    }

    &__search-li:last-child {
        border-bottom: none;
    }

    &__search-btn {
        position: absolute;
        right: 10px;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 20px;
        color: #333;
    }


    &__nav {
        width: 100%;
        height: 40px;

        &-menu {
            display: flex;
            align-items: center;
            height: 40px;
            gap: 5%;
            list-style-type: none;
            margin-left: 5%;
        }
    }

    &__menu-title {
        font-size: 120%;
        cursor: pointer;
        font-family: sans-serif;
        text-decoration: none;
        color: black;
    }

    &__menu-title:hover {
        color: red;
    }

}