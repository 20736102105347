.products {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5%;

    &__nav {
        width: 100%;
        height: 60px;
        background-color: #5492c7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        gap: 5%;
        margin-top: 2%;

        &-li {
            list-style-type: none;
            cursor: pointer;
            font-size: 125%;
            font-family: system-ui;
            text-decoration: none;
            display: flex;
            &-link{
                text-decoration: none;
                color: rgb(29, 28, 28);
            }
            &-link:hover {
                color: red;
            }
        }


    }
    &__navmodel{
        width: 60%;
        height: 60px;
        background-color: #43576a;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        gap: 5%;
        margin-top: 2%;
    }

    &__form {
        gap: 1%;
        &-card {
            width: 350px;
            height: 350px;
            background-image: url('https://img.freepik.com/premium-vector/dark-luxury-elegant-business-background_358261-121.jpg');
            background-position: center;
            background-size: cover;
            margin: 40px 0 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 20px;
            border: 1px solid rgb(146, 146, 146);
            gap: 5%;
        }

        &-photo {
            width: 70%;
            height: 55%;
            cursor: pointer;
            border-radius: 15px;

        }

        &-text {
            cursor: pointer;
            color: rgb(124, 122, 116);
            text-align: center;
            font-size: 127%;
            font-family: system-ui;
            margin-top: 3%;
        }

        &-text:hover {
            color: red;
        }

        &-price {
            cursor: pointer;
            color: rgb(236, 47, 47);
            font-family: sans-serif;
            font-size: 130%;
            padding-top: 1%;
        }

        &-btn {
            font-weight: 700;
            font-size: 13px;
            color: white;
            text-decoration: none;
            padding: .8em 1em calc(.8em + 3px);
            border-radius: 3px;
            background: rgb(64,199,129);
            cursor: pointer;
            margin-top: 6%;
            &-link{
                text-decoration: none;
                color: rgb(0, 0, 0);
            }
        }
    }

    &__sld{
        background-color: #3f5757;
        width: 14%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5%;
        border-radius: 10px;
        &-formbtn{
            cursor: pointer;
            width: 20%;
            height: 66%;
            border: none;
        }
        &-btn{
            text-decoration: none;
            cursor: pointer;
            font-size: 125%;
            width: 40%;
            height: 33px;
            border: none;
            font-family: serif;
            color: black;
            border-radius: 5px;
            background-color: green;
            background: rgb(64, 199, 129);

        }
    }
}
.swiper-slide-sld{
    width: 37% !important; 
    margin-right: 20px !important;
}