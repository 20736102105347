.podrobnee {
    width: 100%;
    height: 100%;
    background-color: rgb(185, 185, 185);
    display: flex;
    align-items: center;

    &__form {
        padding: 7% 0 7% 0;
        gap: 2%;
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &-photo {
            background-image: url('https://img.freepik.com/premium-vector/dark-luxury-elegant-business-background_358261-121.jpg');
            background-position: center;
            background-size: cover;
            width: 45%;
            height: 400px;
            border-radius: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgb(62, 57, 95);

            &-img {
                width: 60%;
                border-radius: 10px;
            }
        }

        &-word {
            width: 45%;
            height: 400px;
            border-radius: 7px;
            border: 1px solid rgb(62, 57, 95);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: space-evenly;
            gap: 4%;
            background-image: url('https://img.freepik.com/premium-vector/dark-luxury-elegant-business-background_358261-121.jpg');
            background-position: center;
            background-size: cover;
            

            &-h1 {
                font-size: 240%;
                font-family: serif;
                font-weight: 800;
                color: rgb(255, 255, 255);
            }
            &-title {
                font-size: 133%;
                font-family: monospace;
                color: rgb(255, 255, 255);
            }
            &-bye{
                display: flex;
                justify-content: center;
                width: 50%;
                align-items: center;
            }

            &-pole {
                width: 40%;
                height: 25px;
                border-radius: 2px;
                border: 1px solid rgb(77, 77, 77);
            }

            &-link {
                cursor: pointer;
            }
            &-btn {
                cursor: pointer;
                width: 120%;
                height: 25px;
                border: 1px solid rgb(94, 21, 21);
                background-color: rgb(0, 140, 255);
                color: white;
                font-size: 140%;
                border-radius: 2px;
                font-family: monospace;
            }
        }
    }
}