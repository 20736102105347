.rek{
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 20px;
    margin-top: 6%;
    &__title{
        padding-top: 2%;
        font-size: 180%;
        font-family: monospace;
        text-align: center;
    }
    &__form{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2%;
        padding-top: 2%;
    }
}