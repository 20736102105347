.contact {
    width: 100%;
    height: 100%;
    padding: 0px 0px 5% 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(19, 18, 18);

    &__title {
        font-family: sans-serif;
        font-size: 160%;
        margin-top: 4%;
        width: 35%;
        border-radius: 2px;
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        height: 45px;
        border: 2px solid rgb(170, 148, 106);
    }
    &__title:hover{
        color: rgb(255, 255, 255);
        background-color: gray;
        font-family: monospace;
    }

    &__form {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding-top: 4%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        &-pole {
                width: 40%;
                border: 1px solid rgb(134, 134, 134);
                padding: 3% 0px 5% 0px;
                /* padding-top: 2%; */
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                background-color: rgb(26, 25, 25);
                

            &-input {
                width: 75%;
                height: 45px;
                outline: none;
                border-radius: 3px;
                padding-left: 1%;
                font-family: serif;
                font-size: 105%;
                border: 1px solid #711717;
            }

            &-btn {
                width: 45%;
                height: 35px;
                color: black;
                font-size: 110%;
                font-family: sans-serif;
            }
        }

        &-tovar {
            border: 1px solid rgb(134, 134, 134);
            width: 40%;
            background-color: gray;
            display: flex;
            align-items: center;
            justify-content: center;

            &-img {
                width: 70%;
                height: 50%;

            }
        }
    }
}