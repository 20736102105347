.cart{
    width: 100%;
    height: 100%;
    padding: 0px 0px 5% 0px;
    
    &__title{
        font-size: 195%;
        font-family: monospace;
        padding-top: 3%;
        margin-left: 2%;
    }
    &__form{
        width: 80%;
        height: 70%;
        margin: 0 auto;
        &-block{
            border: 0.5px solid rgb(137, 137, 137);
            margin-top: 5%;
            display: flex;
            justify-content: center;
            &-corm{
                width: 40%;
                border: none;
                border-radius: 3px;
                height: 150px;
                background-color: rgb(220, 220, 220);
                display: flex;
                align-items: center;
                justify-content: center;

            }
            &-img{
                width: 35%;
            }
            &-cormi{
                width: 40%;
                border: none;
                border-radius: 3px;
                height: 150px;
                background-color: rgb(220, 220, 220);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 12%;
            }
            &-number{
                width: 16%;
                height: 16%;
                border: none;
                border-radius: 2px;
                font-size: 60%;
                text-align: center;
                outline: none;
            }
            &-number:hover{
                background-color: aqua;
            }
            &-btn{
                width: 130%;
                height: 25px;
                border: none;
                border-radius: 2px;
                cursor: pointer;
                font-size: 90%;
                font-family: monospace;
            }
            &-btn:hover{
                background-color: aqua;
            }
        }
    }
}