.home {
    width: 100%;
    height: 555px;

    &__form {
        background-color: #ffffff;
        padding-top: 3%;
    }

    &__card {
        background-color: rgb(104, 146, 136);
        border: 1px solid;
        height: 520px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        &-img {
            background-color: rgb(104, 146, 136);
            display: flex;
            justify-content: space-evenly;

        }

        &-text {
            display: flex;
            align-content: center;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }


        &-name {
            font-size: 225%;
            font-family: emoji;
            color: #6e0909;
        }

        &-ima {
            font-family: monospace;
            width: 50%;
            text-align: center;
        }

        &-price {
            margin-top: 7%;
            font-size: 110%;
            color: #c91919;
        }

        &-bye {
            cursor: pointer;
            width: 180px;
            height: 60px;
            cursor: pointer;
            background: rgb(175 232 255);
            border: none;
            font-size: 120%;
            margin-top: 10%;
            transition: 1s ease-in-out;
        }

        &-photo {
            width: 25%;
        }

    }
}