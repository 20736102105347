.footer{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    background-color: gainsboro;
    justify-content: space-around;
    &__net{
        width: 30%;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &-title{
            font-size: 150%;
            font-family: sans-serif;
        }
        &-text{
            font-size: 100%;
            width: 24%;
            text-decoration: none;
            color: black;
            font-family: serif;
        }
    }
    &__contacts{
        width: 31%;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &-title{
            font-size: 150%;
            font-family: sans-serif;
        }
        &-text{
            font-size: 100%;
            width: 100%;
            text-decoration: none;
            color: black;
            font-family: serif;
            
        }
    }
    &__text{
        font-size: 70%;
        color: rgb(94, 94, 94);
        text-align: center;
        
    }
}