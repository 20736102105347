* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}


@import './components/Header/Header.scss';
@import './components/Main/Home/Home.scss';
@import './components/Footer/Footer.scss';
@import './components//Main/Products/Products.scss';
@import './components/Main/Podrobnee/Podrobnee.scss';
@import './components/Main/Rek/Rek.scss';
@import './components/Main/Cart/Cart.scss';
@import './components/Main/Contact/Contact.scss';

@media(max-width: 1024px) {
    .container {
        width: 93%;
    }

    .contact__title {
        font-size: 145%;
        width: 50%;
    }

    .products__navmodel {
        width: 70%;
    }
    .podrobnee__form-word-btn{
        width: 100%;
        height: 43px;
        font-size: 130%;
    }

    .products__nav-li-link {
        font-size: 90%;
        font-family: serif;
    }

    .footer__net-title {
        font-size: 120%;
    }

    .footer__contacts-title {
        font-size: 120%;
    }

    .footer__net-text {
        width: 40%;
    }

    .footer__contacts-text {
        width: 90%;
        font-size: 95%;
    }
}

@media(max-width: 992px) {
    .footer__contacts-text {
        width: 89%;
    }
    .products__navmodel {
        width: 81%;
    }
    .podrobnee__form-word-btn{
        width: 100%;
        height: 43px;
        font-size: 130%;
    }

    
}

@media (max-width: 768px) {
    .header__home-title {
        font-size: 140%;
    }

    .header__nav-menu {
        margin-left: 2%;
    }

    .header__menu-title {
        font-size: 105%;
    }

    .home {
        width: 99.6%;
        height: 100%;
    }

    .home__card {
        height: 450px;
    }

    .home__card-name {
        font-size: 175%;
    }

    .home__card-ima {
        width: 50%;
        font-size: 90%;
    }

    .home__card-price {
        font-size: 94%;
    }

    .products__form-card {
        width: 333px;
    }

    .products__navmodel {
        width: 86%;
    }

    .products__nav-li-link {
        font-size: 77%;
    }

    .podrobnee {
        width: 100%;
        height: 100%;
    }

    .podrobnee__form {
        width: 100%;
        flex-direction: column;
    }

    .podrobnee__form-photo {
        height: 80%;
        width: 70%;
    }

    .podrobnee__form-word {
        height: 350px;
        width: 70%;
        gap: 0%;
    }

    .podrobnee__form-photo-img {
        width: 77%;
        height: 50%;
    }

    .podrobnee__form-word-title {
        font-size: 120%;
    }

    .podrobnee__form-word-h1 {
        font-size: 185%;
    }

    .podrobnee__form-word-bye {
        width: 60%;
    }

    .podrobnee__form-word-btn {
        width: 100%;
        height: 33px;
        font-size: 130%;
    }


    .cart__form-block-corm {
        width: 45%;
    }

    .cart__form-block-cormi {
        width: 45%;
        justify-content: start;
    }

    .cart__form-block-img {
        width: 65%;
        height: 77%;
    }

    .cart__form-block-btn {
        width: 110%;
        height: 30px;
        font-size: 110%;
    }

    .cart__form-block-number {
        width: 35%;
        height: 30px;
        font-size: 80%;
    }

    .contact__form-pole {
        width: 70%;
    }

    .contact__title {
        font-size: 120%;
        width: 50%;
    }


    .footer__contacts-title {
        font-size: 100%;
    }

    .footer__net-title {
        font-size: 100%;
    }

    .footer__net-text {
        width: 50%;
        font-size: 90%;
    }

    .footer__contacts-text {
        width: 99%;
        font-size: 80%;
    }
}

@media (max-width: 576px) {
    .header__welcome {
        width: 100%;
        height: 100%;
    }

    .header__welcome-title {
        font-size: 85%;
    }

    .header__home {
        height: 50px;
    }

    .header__home-title {
        font-size: 130%;
    }

    .header__menu-title {
        font-size: 95%;
    }

    .home__card {
        height: 400px;
    }

    .home__card-img {
        justify-content: start;
    }

    .home__card-name {
        font-size: 144%;
    }

    .home__card-text {
        width: 60%;
    }

    .home__card-photo {
        width: 30%;
    }

    .products__nav-li-link {
        font-size: 60%;
        font-family: sans-serif;
    }

    .products__navmodel {
        width: 85%;
    }

    .podrobnee__form {
        width: 100%;
    }

    .podrobnee__form-photo-img {
        width: 77%;
        height: 40%;
    }

    .podrobnee__form-word-h1 {
        font-size: 155%;
    }

    .podrobnee__form-photo {
        height: 70%;
    }

    // .podrobnee__form-word {
        
    // }

    .podrobnee__form-word-title {
        font-size: 110%;
    }

    .podrobnee__form-word-btn {
        width: 105%;
        height: 45px;
        font-size: 110%;
    }

    .cart__title {
        font-size: 155%;
    }

    .cart__form {
        width: 90%;
    }

    .cart__form-block-btn {
        width: 90%;
        height: 40px;
        font-size: 100%;
    }

    .cart__form-block-number {
        width: 35%;
        height: 40px;
        font-size: 80%;
    }

    .contact__title {
        font-size: 120%;
        width: 65%;
    }

    .footer__net-text {
        width: 55%;
    }


}

@media (max-width: 480px) {
    .home__card-text {
        width: 55%;
    }

    .home__card-text {
        width: 50%;
    }

    .home__card-photo {
        width: 40%;
    }

    .home {
        width: 99.5%;
        height: 100%;
    }

    .swiper-button-next {
        width: 2% !important;
    }

    .swiper-button-prev {
        width: 2% !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 190% !important;
    }

    .products__nav-li-link {
        font-size: 45%;
        font-weight: 700;
        font-family: sans-serif;
    }

    .products__nav {
        height: 40px;
        gap: 3%;
    }
    .products__navmodel {
        height: 45px;
        gap: 3%;
    }
    .products__form-card{
        width: 310px;
    }
    .cart__form-block-cormi {

        gap: 0%;
    }
    .contact__form-pole {
        width: 80%;
    }

    .contact__title {
        font-size: 80%;
        width: 65%;
    }

    .footer {
        justify-content: space-evenly;
        height: 150px;
    }
    .footer__contacts-text {
        width: 100%;
        font-size: 72%;
    }
    .footer__contacts-title {
        font-size: 80%;
    }
    .footer__net-title {
        font-size: 80%;
    }
    .footer__contacts {
        width: 40%;
    }
    .footer__net {
        width: 40%;
    }
    .footer__net-text {
        width: 60%;
    }
    .footer__text {
        font-size: 40%;
    }
}

@media (max-width: 320px) {
    .header__welcome-title {
        font-size: 75%;
    }
    .header__home {
        height: 50px;
        padding: 0px 10px;
    }
    .header__home-title {
        font-size: 105%;
    }
    .header__menu-title {
        font-size: 80%;
    }
    .home__card {
        height: 360px;
    }
    .home__card-name {
        font-size: 100%;
    }
    .home__card-ima {
        width: 70%;
        font-size: 60%;
    }
    .home__card-price {
        font-size: 69%;
    }
    .products__form-btn{
        font-size: 10px;
    }
    .home__card-photo {
        width: 45%;
    }
    .home__card-text {
        width: 47%;
    }

    .rek__title {
        font-size: 100%;
    }
    .products__form-card {
        height: 255px;
        width: 255px;
    }
    .products__navmodel {
        width: 98%;
    }
    .products__nav-li-link {
        font-size: 33%;
    }
    .podrobnee__form-word {
        height: 250px;
    }
    .podrobnee__form-word-h1 {
        font-size: 115%;
    }
    .podrobnee__form-word-title {
        font-size: 75%;
    }
    .podrobnee__form-word-btn {
        width: 100%;
        height: 36px;
        font-size: 80%;
    }
    .cart__form-block-img {
        width: 60%;
        height: 60%;
    }
    .cart__form-block-btn {
        width: 70%;
        height: 35px;
        font-size: 75%;
    }
    .cart__form-block-number {
        width: 27%;
        height: 35px;
        font-size: 50%;
    }
    .contact__form-pole {
        width: 85%;
    }
    .contact__title {
        font-size: 68%;
        width: 60%;
    }
    .contact__form-pole-input {
        font-size: 75%;
    }
    .footer__net-title {
        font-size: 70%;
    }
    .footer__contacts-title {
        font-size: 70%;
    }
    .footer__net-text {
        font-size: 65%;
        width: 70%;
    }
    .footer__contacts-text {
        width: 100%;
        font-size: 65%;
    }
    
}

@media (max-width: 280px) {
    .header__welcome-title {
        font-size: 60%;
    }
    .header__home {
        height: 40px;
        padding: 0px 10px;
    }
    .header__menu-title {
        font-size: 67%;
    }
    .home__card {
        height: 310px;
    }
    .home__card {
        height: 310px;
    }
    .products__nav {
        height: 40px;
        gap: 3%;
    }
    .products__navmodel {
        width: 98%;
        gap: 3%;
    }
    .contact__title {
        font-size: 70%;
        width: 60%;
    }
    .footer__contacts-text {
        width: 90%;
        font-size: 66%;
    }
    .footer__net-text {
        font-size: 73%;
        width: 60%;
    }
}